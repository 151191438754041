import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import './eds-dropdown.scss';
import {
    ComboBox,
    Dropdown,
    MultiSelect,
    FilterableMultiSelect,
} from '@carbon/react';
import { useForm, useFormGroup } from '../eds-form';
import { defaultDropdownMappingCallback, getLogger } from '../../../features';
import _ from 'lodash';
import { useEffectOnUpdate } from '../../../features/react/hooks';
import { EdsInputElementButton } from '../..';

const logger = getLogger('EdsDropdown');

export const EdsDropdownType = Object.freeze({
    Dropdown: 'DROPDOWN',
    ComboBox: 'COMBOBOX',
    MultiSelect: 'MULTISELECT',
    FilterableMultiSelect: 'FILTERABLE_MULTISELECT',
});

export const EdsDropdown = forwardRef((props, ref) => {
    const {
        type = EdsDropdownType.Dropdown,
        name,
        label,
        forceRequired,
        disabledOnEmpty,
        getDataCallback,
        mappingCallback,
        onChangeCallback,
        dropdownButtonProps,
        disabled,
        ...otherProps
    } = props;
    const inputRef = useRef(null);
    const [showLabel] = useState(props.showLabel ?? false);
    const [items, setItems] = useState(props.items ?? []);
    const [labelText] = useState(label ?? '');
    const [isDisabled, setIsDisabled] = useState(
        (disabled || (props.disabledOnEmpty && _.isEmpty(props.items))) ?? false
    );
    const valueOnBlur = useRef(null);

    const {
        getFormValue,
        handleFormChange,
        formatLabel,
        isInvalid,
        getInvalidText,
    } = useForm();
    const { usePrefix, prefix } = useFormGroup();

    let prefixedName = usePrefix(name) ?? name;
    const [selectedItem, setSelectedItem] = useState({});

    useEffect(() => {
        if (_.isFunction(getDataCallback)) {
            fetchInitData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffectOnUpdate(() => {
        if (_.isFunction(onChangeCallback)) {
            onChangeCallback({ selectedItem: selectedItem });
        }
    }, [selectedItem]);

    useEffectOnUpdate(() => {
        if (disabledOnEmpty) {
            // Disable on empty or enable when not empty
            setIsDisabled(_.isEmpty(items));
        }
    }, [items, disabledOnEmpty]);

    useEffectOnUpdate(() => {
        setIsDisabled(disabled);
    }, [disabled]);

    useEffectOnUpdate(() => {
        if (isDisabled && disabledOnEmpty && hasValue()) {
            clearValue();
        }
    }, [isDisabled]);

    const fetchInitData = async () => {
        const serverData = await getDataCallback();
        processDataFromServer(serverData);
    };

    const processDataFromServer = (serverData) => {
        const mappedData = mapDataToItems(serverData);

        if (_.isArray(mappedData)) {
            setItems(mappedData);
        }
    };

    const mapServerData = (data) => {
        if (_.isFunction(mappingCallback)) {
            return mappingCallback(data);
        } else if (_.isFunction(defaultDropdownMappingCallback)) {
            return defaultDropdownMappingCallback(data);
        }
        return data;
    };

    const mapDataToItems = (data) => {
        if (
            !_.isFunction(mappingCallback) &&
            !_.isFunction(defaultDropdownMappingCallback)
        ) {
            logger.log('No mappingCallback available');
            return data;
        }
        const items = [];
        if (_.isArray(data)) {
            data.map((dataArrayRow) => {
                items.push(mapServerData(dataArrayRow));
            });
        } else {
            items.push(mapServerData(data));
        }
        return items;
    };

    const handleInputChange = (event) => {
        if (!_.isEmpty(event)) {
            //used as initiator when the element is loaded but the selectedItem is not set correctly
            if (_.isNull(selectedItem) || _.isEmpty(selectedItem)) {
                const value = getValue();
                if (
                    !_.isNull(value) &&
                    _.isPlainObject(value) &&
                    event === value?.text
                ) {
                    setSelectedItem(value);
                }
            }
        } else if (!_.isEmpty(selectedItem)) {
            updateSelectedItem(null);
        }
    };

    const handleOnChange = (event) => {
        event.name = prefixedName;
        handleFormChange(event);

        if (_.isFunction(onChangeCallback)) {
            onChangeCallback(event);
        }
    };

    const getSelectedItem = () => {
        return getValue();
    };

    const findItemById = (items, value) => {
        return items.find((item) => item.id === value);
    };

    const updateSelectedItem = (value, findItemCallback = findItemById) => {
        let item;
        if (_.isObject(value)) {
            item = value;
        } else {
            item = findItemCallback(items, value);
            if (_.isUndefined(item)) {
                //if the value is null it can be used to empty the dropdown
                item = value;
            }
        }

        if (!_.isUndefined(item)) {
            setSelectedItem(item);
            handleOnChange({ selectedItem: item });
        }
    };

    const getItems = () => {
        return items;
    };

    const addItem = (newItem, setAsSelectedItem = true) => {
        setItems((previousItems) => [...previousItems, newItem]);

        if (setAsSelectedItem) {
            updateSelectedItem(newItem);
        }
    };

    const updateItems = (newItems) => {
        // Do not update when current and new items are empty
        if (_.isEmpty(items) && _.isEmpty(newItems)) {
            return;
        }

        // Clear selected item when selected item is not in new items array and is not empty
        if (selectedItem && !_.isEmpty(selectedItem)) {
            if (_.isArray(newItems)) {
                const foundItems = newItems.filter((item) => {
                    return item.id === selectedItem.id;
                });
                if (_.isEmpty(foundItems)) {
                    clearValue();
                }
            } else {
                clearValue();
            }
        }
        setItems(newItems);
    };

    const clearValue = () => {
        handleFormChange({
            name: prefixedName,
            selectedItem: null,
        });

        setSelectedItem(null);
    };

    useImperativeHandle(ref, () => ({
        getSelectedItem,
        updateSelectedItem,
        getItems,
        addItem,
        updateItems,
        clearValue,
        getElement() {
            return inputRef.current;
        },
    }));

    const getValue = () => {
        let returnValue = {};
        const value = getFormValue(name, prefix);

        if (
            type !== EdsDropdownType.MultiSelect &&
            type !== EdsDropdownType.FilterableMultiSelect
        ) {
            if (_.isPlainObject(value)) {
                returnValue = value;
            }
        } else {
            if (_.isArray(value)) {
                returnValue = value;
            } else {
                returnValue = [];
            }
        }

        if (isEmptyComboBoxValue(returnValue)) {
            returnValue = { id: undefined, text: '' };
        }

        return returnValue;
    };

    const isEmptyComboBoxValue = (value) => {
        if (type !== EdsDropdownType.ComboBox || !_.isPlainObject(value)) {
            return false;
        }

        if (_.isEmpty(value)) {
            return true;
        }

        for (const prop in value) {
            if (!_.isNil(value[prop])) {
                return false;
            }
        }

        return true;
    };

    const hasValue = () => {
        const value = getValue();
        return (
            !_.isNull(value) &&
            _.isPlainObject(value) &&
            Object.keys(value).length > 0
        );
    };

    const getDropdownComponent = () => {
        const filterItems = (menu) => {
            return menu?.item.text
                ?.toLowerCase()
                .includes(menu?.inputValue?.toLowerCase());
        };

        switch (type) {
            case EdsDropdownType.FilterableMultiSelect: {
                return (
                    <FilterableMultiSelect
                        ref={inputRef}
                        autoAlign={true}
                        direction="bottom"
                        className="eds-dropdown"
                        id={prefixedName}
                        name={prefixedName}
                        label={formatLabel(
                            labelText,
                            name,
                            prefix,
                            forceRequired
                        )}
                        titleText={formatLabel(
                            labelText,
                            name,
                            prefix,
                            forceRequired
                        )}
                        disabled={isDisabled}
                        items={items}
                        shouldFilterItem={filterItems}
                        selectedItems={getValue()}
                        onInputChange={(event) => {
                            handleInputChange(event);
                        }}
                        onChange={(event) => {
                            setTimeout(function () {
                                handleOnChange(event);
                            });
                        }}
                        invalid={isInvalid(prefixedName)}
                        invalidText={getInvalidText(prefixedName)}
                        allowCustomValue={false}
                        itemToString={(item) => (item ? item.text : '')}
                        {...otherProps}
                    />
                );
            }
            case EdsDropdownType.MultiSelect: {
                return (
                    <MultiSelect
                        ref={inputRef}
                        autoAlign={true}
                        direction="bottom"
                        className="eds-dropdown"
                        id={prefixedName}
                        name={prefixedName}
                        label={formatLabel(
                            labelText,
                            name,
                            prefix,
                            forceRequired
                        )}
                        titleText={formatLabel(
                            labelText,
                            name,
                            prefix,
                            forceRequired
                        )}
                        disabled={isDisabled}
                        items={items}
                        shouldFilterItem={filterItems}
                        selectedItems={getValue()}
                        onInputChange={(event) => {
                            handleInputChange(event);
                        }}
                        onChange={(event) => {
                            setTimeout(function () {
                                handleOnChange(event);
                            });
                        }}
                        invalid={isInvalid(prefixedName)}
                        invalidText={getInvalidText(prefixedName)}
                        allowCustomValue={false}
                        itemToString={(item) => (item ? item.text : '')}
                        {...otherProps}
                    />
                );
            }
            case EdsDropdownType.ComboBox: {
                return (
                    <ComboBox
                        ref={inputRef}
                        autoAlign={true}
                        direction="bottom"
                        className="eds-dropdown"
                        id={prefixedName}
                        name={prefixedName}
                        titleText={formatLabel(
                            labelText,
                            name,
                            prefix,
                            forceRequired
                        )}
                        disabled={isDisabled}
                        items={items}
                        shouldFilterItem={filterItems}
                        initialSelectedItem={{}}
                        selectedItem={getValue()}
                        onInputChange={(event) => {
                            handleInputChange(event);
                        }}
                        onChange={(event) => {
                            handleOnChange(event);
                        }}
                        onBlur={(event) => {
                            valueOnBlur.current = event?.currentTarget?.value;

                            setTimeout(() => {
                                valueOnBlur.current = null;
                            }, 500);
                        }}
                        invalid={isInvalid(prefixedName)}
                        invalidText={getInvalidText(prefixedName)}
                        allowCustomValue={false}
                        itemToString={(item) => (item ? item.text : '')}
                        {...otherProps}
                    />
                );
            }
            default:
            case EdsDropdownType.Dropdown: {
                return (
                    <Dropdown
                        ref={inputRef}
                        autoAlign={true}
                        direction="bottom"
                        className="eds-dropdown"
                        id={prefixedName}
                        name={prefixedName}
                        titleText={formatLabel(
                            labelText,
                            name,
                            prefix,
                            forceRequired
                        )}
                        label={showLabel && labelText}
                        disabled={isDisabled}
                        items={items}
                        selectedItem={getValue()}
                        onChange={(event) => {
                            handleOnChange(event);
                        }}
                        invalid={isInvalid(prefixedName)}
                        invalidText={getInvalidText(prefixedName)}
                        itemToString={(item) => (item ? item?.text : '')}
                        {...otherProps}
                    />
                );
            }
        }
    };

    if (_.isFunction(dropdownButtonProps?.onClickCallback)) {
        const getValueOnBlur = () => {
            let result = '';

            if (!_.isNull(valueOnBlur.current)) {
                const lowerCaseValue = valueOnBlur.current.toLowerCase();
                const matches = items.filter(
                    (item) =>
                        item.text
                            .toLowerCase()
                            .localeCompare(lowerCaseValue) === 0
                );

                if (_.isEmpty(matches)) {
                    result = valueOnBlur.current;
                    valueOnBlur.current = null;
                }
            }

            return result;
        };

        return (
            <EdsInputElementButton
                getInputValueCallback={getValueOnBlur}
                {...props.dropdownButtonProps}
            >
                {getDropdownComponent()}
            </EdsInputElementButton>
        );
    } else {
        return getDropdownComponent();
    }
});
EdsDropdown.displayName = 'EdsDropdown';
