import { EdsWizardStep } from '../../../../../components';
import { useTranslation } from 'react-i18next';
import { PersonalCaregiverContactStepForm } from './contact-step-form';

export function PersonalCaregiverContactStep({ id }) {
    const { t } = useTranslation();

    return (
        <EdsWizardStep
            id={id}
            label={t('b98b48004a079f7061d8a2e79fba2689', 'Contact info')}
            secondaryLabel={t(
                '1c36d645323bc95f44b501a29ffa9ad6',
                'Add contact info'
            )}
        >
            <PersonalCaregiverContactStepForm />
        </EdsWizardStep>
    );
}
