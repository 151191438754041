export * from './countries';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import HttpApi from 'i18next-http-backend';
import { Settings } from 'luxon';
import { getLogger, isDevMode } from '../utils';

const logger = getLogger('i18n');

let backend = HttpApi;
let backendOptions = {
    /*eslint no-undef: "off"*/
    loadPath: `${i18n_cdn}/locales/{{lng}}/{{ns}}.json`,
    queryStringParams: {
        /*eslint no-undef: "off"*/
        v: i18n_version,
    },
    crossDomain: true,
    requestOptions: {
        // fetch timeout mechanic
        signal: AbortSignal.timeout(5000),
    },
};

if (isDevMode) {
    let data = import.meta.glob('./locales/**/*.json');
    backend = resourcesToBackend((language, namespace) => {
        logger.log('Load resource', language, namespace);
        let getTranslations = data[`./locales/${language}/${namespace}.json`];
        if (getTranslations) {
            return getTranslations();
        }
        return {};
    });
    backendOptions = {};
}

let config = {
    ns: 'main',
    defaultNS: 'main',
    // lng: 'cimode', //to show keys instead of translations
    fallbackLng: ['en-US', 'dev'],
    load: 'currentOnly',
    debug: isDevMode,
    //when changing these values also update the locales in EdsDatePicker
    supportedLngs: [
        'nl-NL',
        'en-US',
        'en-GB',
        'de',
        'nb-NO', // Norwegian Bokmål
        'nn', // Norwegian Nynorsk
        'sv', // Swedish
        'dev',
    ],
    backend: backendOptions,
};

if (isDevMode) {
    if (!config.lng) config.lng = 'dev';
}

i18n.use(backend).use(LanguageDetector).use(initReactI18next).init(config);

Settings.defaultLocale = i18n.language;

export default i18n;
