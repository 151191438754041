import { useTranslation } from 'react-i18next';
import {
    EdsDataGrid,
    EdsTableDate,
    EdsWizardStep,
    getFullPhoneNumber,
    getFullStreetName,
    getValueOrDefault,
} from '../../../../../components';
import { useForm } from '../../../../../components/eds/eds-form';
import _ from 'lodash';
import {
    getCombinedName,
    getCountryTranslations,
} from '../../../../../features';

export function SummaryStep({ id }) {
    const { t } = useTranslation();

    const countryTranslations = getCountryTranslations(t);

    const { form } = useForm();

    return (
        <EdsWizardStep
            id={id}
            summary={true}
            label={t('a80da1282f2c775bbc5f2c92c836968b', 'Summary')}
            secondaryLabel={t(
                'a7c25db8fc4933eb0d8a724c53b249a5',
                'Personal caregiver summary'
            )}
        >
            <EdsDataGrid
                label={t('6cf31af044e0080f7681302ba1db53f3', 'Personal info')}
                gridData={{
                    styles: {
                        left: {
                            key: {
                                width: '400px',
                            },
                        },
                    },
                    rows: {
                        left: [
                            {
                                key: t(
                                    'b068931cc450442b63f5b3d276ea4297',
                                    'Name'
                                ),
                                value: getCombinedName(form.person),
                            },
                            {
                                key: t(
                                    '1b64f409e392564f28b5b62e894293ab',
                                    'Birthdate'
                                ),
                                value: (
                                    <EdsTableDate
                                        value={form.person?.birthDate}
                                    />
                                ),
                            },
                            {
                                key: t(
                                    '8512ae7d57b1396273f76fe6ed341a23',
                                    'Language'
                                ),
                                value: getValueOrDefault(
                                    form.person?.languageId?.text
                                ),
                            },
                            {
                                key: t(
                                    'cc90f1913b83d255b95be0e0fea6d576',
                                    'Gender'
                                ),
                                value: getValueOrDefault(
                                    form.person?.gender?.text
                                ),
                            },
                            {
                                key: t(
                                    'f610124a07c580056177271d6664e137',
                                    'Caregiver type'
                                ),
                                value: getValueOrDefault(
                                    form?.detail?.caregiverTypeId?.text
                                ),
                            },
                            {
                                key: t(
                                    '911c185c8f3c475cdca2ef9cf12166da',
                                    'Remark'
                                ),
                                value: getValueOrDefault(form?.detail?.remarks),
                            },
                        ],
                    },
                }}
            ></EdsDataGrid>

            <EdsDataGrid
                label={t('b98b48004a079f7061d8a2e79fba2689', 'Contact info')}
                gridData={{
                    styles: {
                        left: {
                            key: {
                                width: '400px',
                            },
                        },
                    },
                    rows: {
                        left: [
                            {
                                key: t(
                                    '0c83f57c786a0b4a39efab23731c7ebc',
                                    'Email'
                                ),
                                value: getValueOrDefault(
                                    form?.contact?.emailAddress
                                ),
                            },
                            {
                                key: t(
                                    '9b88e58612797d6b989681a16621ad63',
                                    'Phone number'
                                ),
                                value: getFullPhoneNumber(form.contact),
                            },
                        ],
                    },
                }}
            ></EdsDataGrid>

            <EdsDataGrid
                label={t('884d9804999fc47a3c2694e49ad2536a', 'Address')}
                gridData={{
                    styles: {
                        left: {
                            key: {
                                width: '400px',
                            },
                        },
                    },
                    rows: {
                        left: [
                            ...(!_.isEmpty(form?.address?.addressName)
                                ? [
                                      {
                                          key: t(
                                              '8d6c792fc6a9a9019f65fe22d4871e82',
                                              'Address name'
                                          ),
                                          value: getValueOrDefault(
                                              form?.address?.addressName
                                          ),
                                      },
                                  ]
                                : []),
                            {
                                key: t(
                                    '1f4a9685e37e4f940d07a9f6b43dc83c',
                                    'Street'
                                ),
                                value: getFullStreetName(form.address),
                            },
                            {
                                key: t(
                                    '6bdbc10dfb9833c9215480c531f3bcd6',
                                    'Additional address info'
                                ),
                                value: getValueOrDefault(
                                    form?.address?.addressField2
                                ),
                            },
                            {
                                key: t(
                                    'c8a03a9875d7fc4c023e1bebb23d07af',
                                    'Postal code'
                                ),
                                value: getValueOrDefault(
                                    form?.address?.postalCode
                                ),
                            },
                            {
                                key: t(
                                    '4ed5d2eaed1a1fadcc41ad1d58ed603e',
                                    'City'
                                ),
                                value: getValueOrDefault(
                                    form?.address?.cityId?.text
                                ),
                            },
                            ...(!_.isEmpty(form?.address?.regionId?.text)
                                ? [
                                      {
                                          key: t(
                                              '960db2ed82202a9706b97775a4269378',
                                              'Region'
                                          ),
                                          value: getValueOrDefault(
                                              form?.address?.regionId?.text
                                          ),
                                      },
                                  ]
                                : []),
                            ...(!_.isEmpty(form?.address?.districtId?.text)
                                ? [
                                      {
                                          key: t(
                                              '6b77ef4b602800a89d88e6e3f93a322c',
                                              'District'
                                          ),
                                          value: getValueOrDefault(
                                              form?.address?.districtId?.text
                                          ),
                                      },
                                  ]
                                : []),
                            {
                                key: t(
                                    'e909c2d7067ea37437cf97fe11d91bd0',
                                    'Country'
                                ),
                                value: getValueOrDefault(
                                    countryTranslations[
                                        form?.address?.countryId?.countryCode
                                    ] || form?.address?.countryId?.text
                                ),
                            },
                        ],
                    },
                }}
            ></EdsDataGrid>

            <EdsDataGrid
                label={t(
                    '4eec452dee7d15acfb78a023b487bf19',
                    'Additional information'
                )}
                gridData={{
                    styles: {
                        left: {
                            key: {
                                width: '400px',
                            },
                        },
                    },
                    rows: {
                        left: [
                            {
                                key: t(
                                    '70a17ffa722a3985b86d30b034ad06d7',
                                    'Order'
                                ),
                                value: getValueOrDefault(form?.linkInfo?.order),
                            },
                            {
                                key: t(
                                    '3a7434581e964a77c33fa7ae8931bc17',
                                    'Relation type'
                                ),
                                value: getValueOrDefault(
                                    form?.linkInfo?.relationTypeId?.text
                                ),
                            },
                            {
                                key: t(
                                    'ce380372c6231ea71ee4d0a798b99959',
                                    'Has key'
                                ),
                                value: getValueOrDefault(
                                    form?.linkInfo?.hasKey
                                ),
                            },
                            {
                                key: t(
                                    'cb09f7b3eaebedc6aecf621e3014e282',
                                    'Next of kin'
                                ),
                                value: getValueOrDefault(
                                    form?.linkInfo?.isNextOfKin
                                ),
                            },
                        ],
                    },
                }}
            ></EdsDataGrid>
        </EdsWizardStep>
    );
}
