import { useTranslation } from 'react-i18next';
import {
    EdsContainer,
    EdsDataGrid,
    EdsForm,
    EdsFormGroupAddress,
    getFullStreetName,
} from '../../../eds';
import _ from 'lodash';
import {
    getCountryTranslations,
    setEmptyFieldToNull,
    useModal,
    useNotifications,
} from '../../../../features';
import { useRevalidator } from 'react-router-dom';
import { useRef } from 'react';

export const UmoAddressTable = ({ address, ...props }) => {
    const { t } = useTranslation();
    const { showSuccess, showError } = useNotifications();
    const { showFormModal } = useModal();
    const formRef = useRef(null);
    const revalidator = useRevalidator();

    const countryTranslations = getCountryTranslations(t);

    const isUKAddressFormat = address?.country?.isUKAddressFormat ?? false;

    const formData = {
        id: {
            value: address.id,
        },
        addressName: {
            value: address.addressName,
        },
        streetName: {
            value: address.streetName,
        },
        houseNumber: {
            value: address.houseNumber,
        },
        postalCode: {
            value: address.postalCode,
        },
        addressField2: {
            value: address.addressField2,
        },
        apartmentNumber: {
            value: address.apartmentNumber,
        },
        countryId: {
            value: {
                text:
                    countryTranslations[address?.country?.countryCode] ||
                    address?.country?.name,
                id: address.countryId,
                isUKAddressFormat: isUKAddressFormat,
                postalCodeFormat: address?.country?.postalCodeFormat,
            },
        },
        cityId: {
            value: {
                text: address?.city?.name,
                id: address.cityId,
            },
        },
        regionId: {
            value: {
                text: address?.region?.name,
                id: address.regionId,
            },
        },
        districtId: {
            value: {
                text: address?.district?.name,
                id: address.districtId,
            },
        },
        ...(props?.showResidenceType && {
            residenceTypeId: {
                value: {
                    text: props?.residenceType?.name,
                    id: props?.residenceType?.id,
                },
            },
        }),
    };

    const saveData = async (postData) => {
        if (!_.isFunction(props.postDataCallback)) {
            throw false;
        }

        return await props.postDataCallback({
            address: {
                ...(postData?.addressName && {
                    addressName: postData.addressName,
                }),
                streetName: postData.streetName,
                houseNumber: postData.houseNumber,
                postalCode: postData.postalCode,
                addressField2: postData.addressField2,
                ...(postData?.apartmentNumber && {
                    apartmentNumber: postData.apartmentNumber,
                }),
                cityId: postData.cityId,
                districtId: postData.districtId,
            },
            ...(postData?.residenceTypeId && {
                residenceTypeId: postData?.residenceTypeId,
            }),
        });
    };

    const onSubmit = async (_event, form, isValid) => {
        if (!isValid) {
            throw false;
        }

        try {
            const postData = setEmptyFieldToNull(form);
            const response = await saveData(postData);

            showSuccess({
                title: t('536a056b710e94b16169efd17a4a657b', 'Saved'),
                content: t(
                    '281aef713c8dc9007cabb1e65448917d',
                    "{{entity}} '{{name}}' is saved",
                    {
                        entity: t(
                            '884d9804999fc47a3c2694e49ad2536a',
                            'Address'
                        ),
                        name: props.name,
                    }
                ),
            });

            revalidator.revalidate();

            return response;
        } catch (error) {
            showError({
                title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                content: t(
                    'df0a9d46baf7315909e4389a04786e3d',
                    'Oops something went wrong'
                ),
            });

            throw error;
        }
    };

    const showDataModal = () => {
        showFormModal({
            editTitle: t('f2b110628b6a17ab18731bdd282bdce6', 'Edit address'),
            formData: formData,
            formRef,
            children: (
                <EdsForm
                    hideDefaultSubmit={true}
                    initValues={formData}
                    onSubmit={onSubmit}
                    ref={formRef}
                >
                    <EdsFormGroupAddress
                        showApartmentNumber={props.showApartmentNumber ?? false}
                        showResidenceType={props.showResidenceType ?? false}
                        initValues={formData}
                    />
                </EdsForm>
            ),
        });
    };

    const getOnHeaderEditClick = () => {
        if (_.isFunction(props.onHeaderEditClick)) {
            return () => props.onHeaderEditClick();
        } else if (_.isFunction(props.postDataCallback)) {
            return () => showDataModal();
        } else {
            return null;
        }
    };

    return (
        <EdsContainer
            title={t('884d9804999fc47a3c2694e49ad2536a', 'Address')}
            onHeaderEditClick={getOnHeaderEditClick()}
            permissions={props.permissions}
        >
            <EdsDataGrid
                gridData={{
                    styles: {
                        left: {
                            key: {
                                width: '400px',
                            },
                        },
                        right: {
                            key: {
                                width: '400px',
                            },
                        },
                    },
                    rows: {
                        left: [
                            ...(isUKAddressFormat
                                ? [
                                      {
                                          key: t(
                                              '8d6c792fc6a9a9019f65fe22d4871e82',
                                              'Address name'
                                          ),
                                          value: address?.addressName,
                                      },
                                  ]
                                : []),
                            {
                                key: t(
                                    '1f4a9685e37e4f940d07a9f6b43dc83c',
                                    'Street'
                                ),
                                value: getFullStreetName(address),
                            },
                            {
                                key: t(
                                    '6bdbc10dfb9833c9215480c531f3bcd6',
                                    'Additional address info'
                                ),
                                value: address?.addressField2,
                            },
                            {
                                key: t(
                                    'c8a03a9875d7fc4c023e1bebb23d07af',
                                    'Postal code'
                                ),
                                value: address?.postalCode,
                            },
                            {
                                key: t(
                                    '4ed5d2eaed1a1fadcc41ad1d58ed603e',
                                    'City'
                                ),
                                value: address?.city?.name,
                            },
                            {
                                key: t(
                                    'e909c2d7067ea37437cf97fe11d91bd0',
                                    'Country'
                                ),
                                value: address?.country?.name,
                            },
                            ...(props.showResidenceType
                                ? [
                                      {
                                          key: t(
                                              '2d02d77d09fdf4c4466181594b8d8739',
                                              'Residence type'
                                          ),
                                          value: props?.residenceType?.name,
                                      },
                                  ]
                                : []),
                        ],
                        right: [
                            ...(isUKAddressFormat //Show empty field for field alignment if Address name is visible
                                ? [{}]
                                : []),
                            {
                                key: '',
                                value: '',
                            },
                            ...(props.showApartmentNumber
                                ? [
                                      {
                                          key: t(
                                              'c2127dce4ee7d70840144b856dd36fe8',
                                              'Apartment number / Detail'
                                          ),
                                          value: address?.apartmentNumber,
                                      },
                                  ]
                                : []),
                            {},
                            {
                                key: t(
                                    '960db2ed82202a9706b97775a4269378',
                                    'Region'
                                ),
                                value: address?.region?.name,
                            },
                            {
                                key: t(
                                    '6b77ef4b602800a89d88e6e3f93a322c',
                                    'District'
                                ),
                                value: address?.district?.name,
                            },
                            ...(props?.showResidenceType ? [{}] : []),
                        ],
                    },
                }}
            />
        </EdsContainer>
    );
};
