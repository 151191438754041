import './eds-page.scss';
import { Column, Grid } from '@carbon/react';
import { EdsBreadcrumb } from '../';
import { useDocumentTitle } from '../../../features/react/hooks';
import { useEffect } from 'react';

export function EdsPage(props) {
    const documentTitle = useDocumentTitle(props.title);

    useEffect(() => {
        document.title = documentTitle;
    }, [documentTitle]);

    return (
        <div className="eds-page">
            <Grid className="page__banner page-header" fullWidth>
                {props.breadcrumb && (
                    <Column
                        sm={4}
                        md={8}
                        lg={16}
                        className="landing-page__banner"
                    >
                        <EdsBreadcrumb></EdsBreadcrumb>
                    </Column>
                )}

                <Column className="title-container" sm={4} md={8} lg={16}>
                    {props.title && (
                        <h1 className="page-title">{props.title}</h1>
                    )}

                    {props.subtitle && (
                        <div className="page-subtitle">{props.subtitle}</div>
                    )}
                </Column>
            </Grid>
            <Grid fullWidth>
                <Column className="content" sm={4} md={8} lg={16}>
                    {props.children}
                </Column>
            </Grid>
        </div>
    );
}
