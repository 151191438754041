import { Row } from '@carbon/react';
import { useTranslation } from 'react-i18next';
import {
    EdsFormColumn,
    EdsFormGroup,
    EdsPhoneNumberType,
    EdsPhoneNumberTypeFormat,
    EdsTextInput,
    getAllFormatsDefaultPhoneNumberType,
} from '../..';

import _ from 'lodash';
import { useEffectOnMount } from '../../../../features/react/hooks';
import { useState } from 'react';
import { EdsPhoneNumber } from '../../eds-phone-number/eds-phone-number';

export function EdsFormGroupContact({
    phoneTypeFormat = EdsPhoneNumberTypeFormat.E164,
    ...props
}) {
    const [formDefinition, setFormDefinition] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [validateE164Format, setValidateE164Format] = useState(false);

    const { t } = useTranslation();

    useEffectOnMount(async () => {
        setFormDefinition(await getFormDefinition());
    });

    const getFormDefinition = async () => {
        return {
            emailAddress: {
                validation: {
                    type: 'email',
                    max: 80,
                    required: false,
                },
            },
            number: {
                validation: {
                    type: 'phone-number',
                    max: 50,
                    required: false,
                },
                dependents: ['phoneNumberTypeId'],
            },
            phoneNumberTypeId: {
                value: await getAllFormatsDefaultPhoneNumberType(
                    phoneTypeFormat
                ),
                mapping: 'id',
                validation: {
                    type: 'dropdown',
                    required: false,
                    tests: [
                        {
                            name: 'phone-number-type-mandatory',
                            message: t(
                                '894b19ffd439f1a6c41378f2fb98d10a',
                                'Phone number type is required when a phone number is filled in.'
                            ),
                            func: async (value, testContext) => {
                                const contact = props.prefix
                                    ? testContext?.options?.context?.form[
                                          props.prefix
                                      ]
                                    : testContext?.options?.context?.form;

                                return (
                                    !_.isNil(value?.id) ||
                                    _.isNil(contact.number) ||
                                    _.isEmpty(contact.number)
                                );
                            },
                        },
                    ],
                },
            },
            ...(!_.isUndefined(props.wizardStepId) && {
                wizardStepId: props.wizardStepId,
            }),
        };
    };

    return (
        <EdsFormGroup
            prefix={props.prefix}
            formDefinition={formDefinition}
            inStep={props.wizardStepId ?? false}
            label={t('b98b48004a079f7061d8a2e79fba2689', 'Contact info')}
        >
            <Row>
                <EdsFormColumn>
                    <EdsTextInput
                        name="emailAddress"
                        label={t('0c83f57c786a0b4a39efab23731c7ebc', 'Email')}
                    />
                </EdsFormColumn>
            </Row>
            <Row>
                <EdsFormColumn>
                    <EdsPhoneNumber
                        name="number"
                        label={t(
                            '9b88e58612797d6b989681a16621ad63',
                            'Phone number'
                        )}
                        onInitValue={(number) => {
                            setPhoneNumber(number);
                        }}
                        onChangeCallback={(event) => {
                            const number = event?.target?.value;
                            setPhoneNumber(number);
                        }}
                        validateE164Format={validateE164Format}
                    />
                </EdsFormColumn>
                <EdsFormColumn>
                    <EdsPhoneNumberType
                        name="phoneNumberTypeId"
                        label={t(
                            'e1ef131918144ec7adeb7ed253dec2ea',
                            'Phone number type'
                        )}
                        phoneTypeFormat={phoneTypeFormat}
                        forceRequired={
                            !_.isNil(phoneNumber) && !_.isEmpty(phoneNumber)
                        }
                        onChangeCallback={(event) => {
                            if (!_.isUndefined(event)) {
                                setValidateE164Format(
                                    event?.selectedItem?.validateE164Format ??
                                        true
                                );
                            }
                        }}
                    />
                </EdsFormColumn>
            </Row>
        </EdsFormGroup>
    );
}
