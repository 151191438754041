import { EdsWizardStep } from '../../../../../components';
import { useTranslation } from 'react-i18next';
import { PersonalCaregiverDetailsStepForm } from './details-step-form';

export function PersonalCaregiverDetailsStep({ id, showEmail }) {
    const { t } = useTranslation();

    return (
        <EdsWizardStep
            id={id}
            label={t('367615897ad8d9bdadf89f4557c87a7a', 'Personal caregiver')}
            secondaryLabel={t(
                '72246dbed44123317f2d15e468849880',
                'Add details'
            )}
        >
            <PersonalCaregiverDetailsStepForm showEmail={showEmail} />
        </EdsWizardStep>
    );
}
