import { useRevalidator, useRouteLoaderData } from 'react-router-dom';
import {
    EdsContainer,
    EdsContainerGrid,
    EdsDataGrid,
    EdsForm,
    EdsPhoneNumberTypeFormat,
    EdsTableDate,
    UmoAddressTable,
    UmoPhoneNumbersTable,
} from '../../../../../components';
import {
    CareProviderUpdatePermissionSet,
    getGenderLabel,
    getLogger,
    getPersonalCaregiverPhoneNumbers,
    patchPersonalCaregiver,
    postPersonalCaregiverPhoneNumber,
    setEmptyFieldToNull,
    useModal,
    useNotifications,
} from '../../../../../features';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { PersonalCaregiverDetailsStepForm } from '../new/details-step-form';

const logger = getLogger('PersonalCaregiverTab');

function PersonalCaregiverTab() {
    const { t } = useTranslation();
    const { showSuccess, showError } = useNotifications();
    const { showFormModal } = useModal();
    const formRef = useRef(null);
    const revalidator = useRevalidator();

    const loaderData = useRouteLoaderData('personal-caregiver/id');
    const personalCaregiver = loaderData?.personalCaregiverResponse ?? {};

    logger.log('personalCaregiver', personalCaregiver);

    const showDataModalPersonalInfo = () => {
        const formData = {
            id: {
                value: personalCaregiver.id,
            },
            person: {
                firstName: {
                    value: personalCaregiver?.person?.firstName,
                },
                lastName: {
                    value: personalCaregiver?.person?.lastName,
                },
                insertion: {
                    value: personalCaregiver?.person?.insertion,
                },
                gender: {
                    id: personalCaregiver?.person?.gender,
                    text: getGenderLabel(
                        t,
                        personalCaregiver?.person?.gender.toLowerCase()
                    ),
                },
                prefixId: {
                    value: {
                        text: personalCaregiver?.person?.prefix?.name,
                        id: personalCaregiver?.person?.prefixId,
                    },
                },
                birthDate: personalCaregiver?.person?.birthDate,
                languageId: {
                    value: {
                        id: personalCaregiver?.person?.languageId,
                        text: personalCaregiver?.person?.language?.name,
                    },
                },
                emailAddress: {
                    value: personalCaregiver?.emailAddress,
                },
            },
            detail: {
                caregiverTypeId: {
                    value: {
                        text: personalCaregiver?.caregiverType?.name,
                        id: personalCaregiver?.caregiverTypeId,
                    },
                },
                remarks: {
                    value: personalCaregiver.remarks,
                },
            },
        };

        showFormModal({
            editTitle: t(
                '0593a8707d2aad0dc0877003ffe854e8',
                'Edit personal info'
            ),
            formData: formData,
            formRef,
            children: (
                <EdsForm
                    hideDefaultSubmit={true}
                    initValues={formData}
                    onSubmit={async (_event, form, isValid) => {
                        if (!isValid) {
                            throw false;
                        }

                        try {
                            const postData = setEmptyFieldToNull(form);
                            const response = await patchPersonalCaregiver(
                                postData.id,
                                {
                                    personalCaregiverRequest: {
                                        person: {
                                            firstName:
                                                postData?.person?.firstName,
                                            insertion:
                                                postData?.person?.insertion,
                                            lastName:
                                                postData?.person?.lastName,
                                            prefixId:
                                                postData?.person?.prefixId,
                                            gender: postData?.person?.gender,
                                            birthDate:
                                                postData?.person.birthDate,
                                            languageId:
                                                postData?.person?.languageId,
                                        },
                                        emailAddress:
                                            postData?.person?.emailAddress,
                                        caregiverTypeId:
                                            postData?.detail?.caregiverTypeId,
                                        remarks: postData?.detail?.remarks,
                                    },
                                }
                            );

                            showSuccess({
                                title: t(
                                    '536a056b710e94b16169efd17a4a657b',
                                    'Saved'
                                ),
                                content: t(
                                    '281aef713c8dc9007cabb1e65448917d',
                                    "{{entity}} '{{name}}' is saved",
                                    {
                                        entity: t(
                                            '6cf31af044e0080f7681302ba1db53f3',
                                            'Personal info'
                                        ),
                                        name: response
                                            ?.personalCaregiverResponse?.person
                                            ?.combinedName,
                                    }
                                ),
                            });

                            revalidator.revalidate();

                            return response;
                        } catch (error) {
                            showError({
                                title: t(
                                    'cb5e100e5a9a3e7f6d1fd97512215282',
                                    'Error'
                                ),
                                content: t(
                                    'df0a9d46baf7315909e4389a04786e3d',
                                    'Oops something went wrong'
                                ),
                            });

                            throw error;
                        }
                    }}
                    ref={formRef}
                >
                    <PersonalCaregiverDetailsStepForm
                        initValues={formData}
                        showEmail={true}
                    />
                </EdsForm>
            ),
        });
    };

    return (
        <EdsContainerGrid>
            <EdsContainer
                title={t('6cf31af044e0080f7681302ba1db53f3', 'Personal info')}
                onHeaderEditClick={() => showDataModalPersonalInfo()}
                permissions={{ edit: CareProviderUpdatePermissionSet }}
            >
                <EdsDataGrid
                    gridData={{
                        styles: {
                            left: {
                                key: {
                                    width: '400px',
                                },
                            },
                            right: {
                                key: {
                                    width: '400px',
                                },
                            },
                        },
                        rows: {
                            left: [
                                {
                                    key: t(
                                        'b068931cc450442b63f5b3d276ea4297',
                                        'Name'
                                    ),
                                    value: personalCaregiver?.person
                                        ?.combinedName,
                                },
                                {
                                    key: t(
                                        '1b64f409e392564f28b5b62e894293ab',
                                        'Birthdate'
                                    ),
                                    value: (
                                        <EdsTableDate
                                            value={
                                                personalCaregiver?.person
                                                    ?.birthDate
                                            }
                                        />
                                    ),
                                },
                                {
                                    key: t(
                                        '8512ae7d57b1396273f76fe6ed341a23',
                                        'Language'
                                    ),
                                    value: personalCaregiver?.person?.language
                                        ?.name,
                                },
                                {
                                    key: t(
                                        'cc90f1913b83d255b95be0e0fea6d576',
                                        'Gender'
                                    ),
                                    value: getGenderLabel(
                                        t,
                                        personalCaregiver?.person?.gender.toLowerCase()
                                    ),
                                },
                                {
                                    key: t(
                                        'f610124a07c580056177271d6664e137',
                                        'Caregiver type'
                                    ),
                                    value: personalCaregiver?.caregiverType
                                        ?.name,
                                },
                                {
                                    key: t(
                                        '0c83f57c786a0b4a39efab23731c7ebc',
                                        'Email'
                                    ),
                                    value: personalCaregiver?.emailAddress,
                                },
                                {
                                    key: t(
                                        '911c185c8f3c475cdca2ef9cf12166da',
                                        'Remark'
                                    ),
                                    value: personalCaregiver?.remarks,
                                },
                            ],
                            right: [],
                        },
                    }}
                />
            </EdsContainer>

            <UmoAddressTable
                address={personalCaregiver?.address}
                name={personalCaregiver?.person?.combinedName}
                postDataCallback={(address) =>
                    patchPersonalCaregiver(personalCaregiver.id, {
                        personalCaregiverRequest: address,
                    })
                }
                permissions={{ edit: CareProviderUpdatePermissionSet }}
            />

            <UmoPhoneNumbersTable
                phoneTypeFormat={EdsPhoneNumberTypeFormat.E164}
                getDataCallback={() =>
                    getPersonalCaregiverPhoneNumbers(personalCaregiver.id, true)
                }
                postDataCallback={(number) =>
                    postPersonalCaregiverPhoneNumber(
                        personalCaregiver.id,
                        number
                    )
                }
                permissions={{
                    new: CareProviderUpdatePermissionSet,
                    edit: CareProviderUpdatePermissionSet,
                    delete: CareProviderUpdatePermissionSet,
                }}
            />
        </EdsContainerGrid>
    );
}

export default PersonalCaregiverTab;
