import { Row } from '@carbon/react';
import { getCaregiverTypes } from '../../../../../features';
import {
    EdsDropdown,
    EdsDropdownType,
    EdsFormColumn,
    EdsFormGroup,
    EdsFormGroupPerson,
    EdsTextInput,
} from '../../../../../components';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

export function PersonalCaregiverDetailsStepForm(props) {
    const { t } = useTranslation();

    const formDefinition = {
        ...(!_.isUndefined(props.wizardStepId) && {
            wizardStepId: props.wizardStepId,
        }),
        detail: {
            caregiverTypeId: {
                validation: {
                    type: 'dropdown',
                    required: true,
                },
                mapping: 'id',
            },
            remarks: {
                validation: {
                    max: 80,
                    required: false,
                },
            },
        },
    };

    return (
        <EdsFormGroup formDefinition={formDefinition}>
            <EdsFormGroupPerson
                label={t('6cf31af044e0080f7681302ba1db53f3', 'Personal info')}
                prefix="person"
                wizardStepId={props.wizardStepId}
                showPersonalInfo={true}
                showEmail={props.showEmail}
            />
            <EdsFormGroup
                label={t(
                    '3b7f4908c9fe9a7ec75c120b3f186829',
                    'Caregiver detail'
                )}
                prefix="detail"
                inStep={props.wizardStepId ?? false}
            >
                <Row>
                    <EdsFormColumn>
                        <EdsDropdown
                            name="caregiverTypeId"
                            label={t(
                                'f610124a07c580056177271d6664e137',
                                'Caregiver type'
                            )}
                            type={EdsDropdownType.ComboBox}
                            getDataCallback={getCaregiverTypes}
                        />
                    </EdsFormColumn>
                    <EdsFormColumn>
                        <EdsTextInput
                            name="remarks"
                            label={t(
                                '911c185c8f3c475cdca2ef9cf12166da',
                                'Remark'
                            )}
                        />
                    </EdsFormColumn>
                </Row>
            </EdsFormGroup>
        </EdsFormGroup>
    );
}
