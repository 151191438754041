import {
    EdsFormGroup,
    EdsFormGroupAddress,
    EdsFormGroupContact,
} from '../../../../../components';

export function PersonalCaregiverContactStepForm(props) {
    return (
        <EdsFormGroup>
            <EdsFormGroup>
                <EdsFormGroupContact
                    prefix="contact"
                    wizardStepId={props.wizardStepId}
                />
                <EdsFormGroupAddress
                    prefix="address"
                    wizardStepId={props.wizardStepId}
                ></EdsFormGroupAddress>
            </EdsFormGroup>
        </EdsFormGroup>
    );
}
