import { useRef } from 'react';
import { EdsPage, EdsWizard } from '../../../../../components';
import {
    postPersonalCaregiverPhoneNumber,
    stateManagement,
} from '../../../../../features/uds/personal-caregiver';
import { PersonalCaregiverDetailsStep } from './details-step';
import { PersonalCaregiverContactStep } from './contact-step';
import AdditionalInformationStep from '../../../../../components/umo/umo-container-table/umo-linked-personal-caregivers-table/additional-information-step';
import { SummaryStep } from './summary-step';
import {
    setEmptyFieldToNull,
    useNotifications,
    useNavigate,
    getLogger,
    postClientPersonalCaregiver,
} from '../../../../../features';
import { useRouteLoaderData } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const logger = getLogger('PersonalCaregiverPageNew');

function PersonalCaregiverPageNew() {
    const client = useRouteLoaderData('client/id');
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { showSuccess, showError } = useNotifications();
    const formRef = useRef(null);
    const wizardRef = useRef(null);

    return (
        <EdsPage
            title={t(
                'c944a7c4f738a456e2ab18abd2258066',
                'New personal caregiver'
            )}
            breadcrumb={true}
        >
            <EdsWizard
                stateManagement={stateManagement}
                ref={{
                    formRef: formRef,
                    wizardRef: wizardRef,
                }}
                onSubmit={async (event, form, isValid) => {
                    if (!isValid) {
                        return false;
                    }

                    const { address, detail, person, contact, linkInfo } =
                        setEmptyFieldToNull(form, ['wizardStepId']);

                    if (_.isEmpty(linkInfo?.order)) {
                        //order can be empty but when empty it must be set to 0 for UDS
                        linkInfo.order = 0;
                    }

                    const caregiverData = {
                        personalCaregiverRequest: {
                            address,
                            ...detail,
                            person,
                            emailAddress: contact?.emailAddress,
                        },
                        clientPersonalCaregiverLinkRequest: linkInfo,
                    };

                    try {
                        const personalCaregiverResponse =
                            await postClientPersonalCaregiver(
                                client.id,
                                caregiverData
                            );

                        logger.log(
                            'Save personal caregiver response: ',
                            personalCaregiverResponse
                        );

                        if (!_.isEmpty(contact.number)) {
                            const personalCaregiverPhoneNumberResponse =
                                await postPersonalCaregiverPhoneNumber(
                                    personalCaregiverResponse
                                        .personalCaregiverResponse?.id,
                                    {
                                        phoneNumberTypeId:
                                            contact.phoneNumberTypeId,
                                        number: contact.number,
                                    }
                                );

                            logger.log(
                                'Save personal caregiver phone number response: ',
                                personalCaregiverPhoneNumberResponse
                            );
                        } else {
                            logger.info('No phone number added to save');
                        }

                        showSuccess({
                            title: t(
                                '536a056b710e94b16169efd17a4a657b',
                                'Saved'
                            ),
                            content: t(
                                '281aef713c8dc9007cabb1e65448917d',
                                "{{entity}} '{{name}}' is saved",
                                {
                                    entity: t(
                                        '367615897ad8d9bdadf89f4557c87a7a',
                                        'Personal caregiver'
                                    ),
                                    name: personalCaregiverResponse
                                        ?.personalCaregiverResponse?.person
                                        ?.combinedName,
                                }
                            ),
                            actionButtonLabel: t(
                                'fa60a3cda9384edfe8649ddeac758d2b',
                                'Add another personal caregiver'
                            ),
                            onActionButtonClick: (removeNotification) => {
                                navigate(
                                    `/crm/client/${client.id}/caregivers/new`
                                );
                                removeNotification();
                            },
                            ttl: 8000,
                        });

                        navigate(
                            `/crm/client/${client.id}/caregivers/${personalCaregiverResponse.personalCaregiverResponse?.id}`
                        );
                    } catch (err) {
                        logger.log('Error', err);

                        showError({
                            title: t(
                                'cb5e100e5a9a3e7f6d1fd97512215282',
                                'Error'
                            ),
                            content: t(
                                'df0a9d46baf7315909e4389a04786e3d',
                                'Oops something went wrong'
                            ),
                        });
                        return false;
                    }

                    return true;
                }}
                onCancelClick={() => {
                    navigate(`/crm/client/${client.id}/caregivers`);
                }}
            >
                <PersonalCaregiverDetailsStep id="personal-caregiver-details" />
                <PersonalCaregiverContactStep id="personal-caregiver-contact" />
                <AdditionalInformationStep
                    id="personal-link-info"
                    label={t(
                        '4eec452dee7d15acfb78a023b487bf19',
                        'Additional information'
                    )}
                    secondaryLabel={t(
                        'cdf72064e29960dc981b0eff26aa824c',
                        'Add link info'
                    )}
                />
                <SummaryStep id="personal-caregiver-summary" />
            </EdsWizard>
        </EdsPage>
    );
}
export default PersonalCaregiverPageNew;
