import { Row } from '@carbon/react';
import { useTranslation } from 'react-i18next';
import { EdsFormGroup } from '../eds-form-group/eds-form-group';
import { EdsFormColumn } from '../eds-form-column/eds-form-column';
import { EdsDropdown, EdsDropdownType } from '../../eds-dropdown/eds-dropdown';
import { EdsTextInput } from '../../eds-text-input/eds-text-input';
import {
    genders,
    getAccountValidationMethod,
    getGenderLabel,
    getLanguages,
    getMaritalStates,
    getPrefixes,
    testSocialSecurityNumber,
    getDate,
} from '../../../../features';
import _ from 'lodash';
import { useRef, useState } from 'react';
import { useEffectOnMount } from '../../../../features/react/hooks';
import { EdsCheckbox } from '../../eds-checkbox/eds-checkbox';
import { EdsDatePicker } from '../../eds-date-picker/eds-date-picker';

export function EdsFormGroupPerson(props) {
    const [formDefinition, setFormDefinition] = useState([]);
    const {
        showPersonalInfo = false,
        showMaritalState = false,
        showSocialSecurityNumber = false,
        showEmail = false,
        showDisable = false,
    } = props;
    const [prefix] = useState(props.prefix ?? null);
    const [prefixId, setPrefixId] = useState(null);
    const [validationMethodDescription, setValidationMethodDescription] =
        useState(null);
    const genderRef = useRef(null);

    const { t } = useTranslation();

    const getDefaultGender = () => {
        return { id: 'Unknown', text: genders.Unknown };
    };

    useEffectOnMount(async () => {
        setFormDefinition(await getFormDefinition());
    });

    const getFormDefinition = async () => {
        let validationMethod = null;

        if (showSocialSecurityNumber) {
            validationMethod = await getAccountValidationMethod();
            setValidationMethodDescription(
                validationMethod?.description ?? null
            );
        }

        return {
            prefixId: {
                validation: {
                    type: 'dropdown',
                    required: false,
                },
                mapping: 'id',
            },
            firstName: {
                validation: {
                    max: 30,
                    required: false,
                },
            },
            lastName: {
                validation: {
                    max: 80,
                    required: true,
                },
            },
            insertion: {
                validation: {
                    required: false,
                    max: 10,
                },
            },
            gender: {
                value: getDefaultGender(),
                validation: {
                    type: 'dropdown',
                    required: showPersonalInfo,
                },
                mapping: 'id',
            },
            ...(showDisable && {
                disabled: {
                    value: false,
                    validation: {
                        required: false,
                    },
                },
            }),
            ...(showPersonalInfo && {
                birthDate: {
                    validation: {
                        required: false,
                    },
                },
                languageId: {
                    validation: {
                        type: 'dropdown',
                        required: false,
                    },
                    mapping: 'id',
                },
            }),
            ...(showMaritalState && {
                maritalStateId: {
                    validation: {
                        type: 'dropdown',
                        required: false,
                    },
                    mapping: 'id',
                },
            }),
            ...(showSocialSecurityNumber && {
                socialSecurityNumber: {
                    validation: {
                        required: false,
                        max: 20,
                        tests: [
                            {
                                name: 'social-security-number-validation',
                                message: '', // Custom error using testContext.createError(...)
                                func: async (value, testContext) => {
                                    const ssnValidationMethod = prefix
                                        ? testContext?.options?.context?.form[
                                              prefix
                                          ]?.ssnValidationMethod
                                        : testContext?.options?.context?.form
                                              ?.ssnValidationMethod;

                                    if (_.isUndefined(ssnValidationMethod)) {
                                        return true;
                                    }

                                    const validationConfig = {
                                        isUnique: ssnValidationMethod?.isUnique,
                                        externalIdentifierTypeId:
                                            ssnValidationMethod.externalIdentifierTypeId,
                                        validationMethodId:
                                            ssnValidationMethod?.validationMethodId,
                                        validationMethodDescription:
                                            ssnValidationMethod?.description,
                                    };
                                    const errorMessage =
                                        await testSocialSecurityNumber(
                                            value,
                                            validationConfig,
                                            t
                                        );

                                    if (_.isEmpty(errorMessage)) {
                                        return true;
                                    } else {
                                        return testContext.createError({
                                            message: errorMessage,
                                        });
                                    }
                                },
                            },
                        ],
                    },
                },
                ssnValidationMethod: {
                    value: validationMethod,
                    validation: {
                        type: 'dropdown',
                        required: false,
                    },
                    mapping: 'id',
                },
            }),
            ...(showEmail && {
                emailAddress: {
                    validation: {
                        type: 'email',
                        max: 80,
                        required: false,
                    },
                },
            }),
            ...(!_.isUndefined(props.wizardStepId) && {
                wizardStepId: props.wizardStepId,
            }),
        };
    };

    const dropdownPrefixMappingCallback = (data) => {
        return { id: data.id, text: data.name, gender: data.gender };
    };

    const getGenders = () => {
        let returnData = [];
        for (var gender in genders) {
            returnData.push({
                id: gender,
                name: getGenderLabel(t, genders[gender].toLowerCase()),
            });
        }

        return returnData;
    };

    return (
        <EdsFormGroup
            prefix={props.prefix}
            formDefinition={formDefinition}
            inStep={props.wizardStepId ?? false}
            label={
                props.label ??
                t('b2e3cf73322ac5cead91a3c70d47caee', 'Person info')
            }
        >
            <Row>
                <EdsFormColumn>
                    <EdsDropdown
                        name="prefixId"
                        label={t('851f5ac9941d720844d143ed9cfcf60a', 'Prefix')}
                        type={EdsDropdownType.ComboBox}
                        getDataCallback={getPrefixes}
                        mappingCallback={dropdownPrefixMappingCallback}
                        onChangeCallback={(event) => {
                            setPrefixId(event.selectedItem?.id);

                            const selectedGender = !_.isNil(
                                event.selectedItem?.gender
                            )
                                ? event.selectedItem?.gender
                                : genders.Unknown;

                            genderRef.current?.updateSelectedItem(
                                selectedGender
                            );
                        }}
                    ></EdsDropdown>
                </EdsFormColumn>
            </Row>
            <Row>
                <EdsFormColumn>
                    <EdsTextInput
                        name="firstName"
                        label={t(
                            '9461dfec80b7485cd052414128668fa8',
                            'First name'
                        )}
                    />
                </EdsFormColumn>
                <EdsFormColumn>
                    <EdsTextInput
                        name="insertion"
                        label={t(
                            'e0e39b991692259764469b25bfe10b47',
                            'Insertion'
                        )}
                    />
                </EdsFormColumn>
            </Row>
            <Row>
                <EdsFormColumn>
                    <EdsTextInput
                        name="lastName"
                        label={t(
                            'd290ae18b3c8c67f619a3c87b398f323',
                            'Last name'
                        )}
                    />
                </EdsFormColumn>
            </Row>
            {showDisable && (
                <Row>
                    <EdsFormColumn>
                        <EdsCheckbox
                            name="disabled"
                            labelText={t(
                                '888d0ee361af3603736f32131e7b20a2',
                                'Archive'
                            )}
                        />
                    </EdsFormColumn>
                </Row>
            )}
            {showPersonalInfo && (
                <Row>
                    <EdsFormColumn>
                        <EdsDropdown
                            ref={genderRef}
                            name="gender"
                            label={t(
                                'cc90f1913b83d255b95be0e0fea6d576',
                                'Gender'
                            )}
                            readOnly={!_.isNil(prefixId)}
                            getDataCallback={getGenders}
                        />
                    </EdsFormColumn>
                    <EdsFormColumn>
                        <EdsDatePicker
                            name={'birthDate'}
                            label={t(
                                '1b64f409e392564f28b5b62e894293ab',
                                'Birthdate'
                            )}
                            minDate={getDate({ minusYears: 120 })}
                            maxDate={getDate()}
                        />
                    </EdsFormColumn>
                </Row>
            )}
            {(showPersonalInfo || showMaritalState) && (
                <Row>
                    {showPersonalInfo && (
                        <EdsFormColumn>
                            <EdsDropdown
                                name={'languageId'}
                                label={t(
                                    '8512ae7d57b1396273f76fe6ed341a23',
                                    'Language'
                                )}
                                type={EdsDropdownType.ComboBox}
                                getDataCallback={getLanguages}
                            ></EdsDropdown>
                        </EdsFormColumn>
                    )}
                    {showMaritalState && (
                        <EdsFormColumn>
                            <EdsDropdown
                                name={'maritalStateId'}
                                label={t(
                                    '093c346f612429cdfa02b440d89e7a9b',
                                    'Marital state'
                                )}
                                type={EdsDropdownType.ComboBox}
                                getDataCallback={getMaritalStates}
                            ></EdsDropdown>
                        </EdsFormColumn>
                    )}
                </Row>
            )}
            {showSocialSecurityNumber && (
                <Row>
                    <EdsFormColumn>
                        <EdsTextInput
                            name="socialSecurityNumber"
                            label={t(
                                '533c6e90083c9312631bd50350d09c2f',
                                'Social security number'
                            )}
                        ></EdsTextInput>
                    </EdsFormColumn>
                    {!_.isNull(validationMethodDescription) && (
                        <EdsFormColumn>
                            <EdsTextInput
                                name="ssnValidationMethodDescription"
                                label={t(
                                    '859ada64eceea53401403388f935a67e',
                                    'Validation method'
                                )}
                                uncontrolledValue={validationMethodDescription}
                                readOnly={true}
                            ></EdsTextInput>
                        </EdsFormColumn>
                    )}
                </Row>
            )}
            {showEmail && (
                <Row>
                    <EdsFormColumn>
                        <EdsTextInput
                            name="emailAddress"
                            label={t(
                                '0c83f57c786a0b4a39efab23731c7ebc',
                                'Email'
                            )}
                        />
                    </EdsFormColumn>
                </Row>
            )}
        </EdsFormGroup>
    );
}
