import {
    createStateManagementFunctions,
    deleteRequest,
    getRequest,
    patchRequest,
    postRequest,
    putRequest,
} from '../api';

const basePath = '/personal-caregivers';

export const getPersonalCaregiver = async (id) => {
    return getRequest(`${basePath}/${id}`);
};

export const putPersonalCaregiver = async (personalCaregiver) => {
    return putRequest(`${basePath}/${personalCaregiver.id}`, personalCaregiver);
};

export const deletePersonalCaregiver = async (id) => {
    return deleteRequest(`${basePath}/${id}`);
};

export const patchPersonalCaregiver = async (id, personalCaregiver) => {
    return patchRequest(`${basePath}/${id}`, personalCaregiver);
};

export const getPersonalCaregiverLink = async (id) => {
    return getRequest(`${basePath}/${id}/link`);
};

export const putPersonalCaregiverLink = async (personalCaregiver) => {
    return putRequest(
        `${basePath}/${personalCaregiver.id}/link`,
        personalCaregiver
    );
};

export const patchPersonalCaregiverLink = async (id, personalCaregiver) => {
    return patchRequest(`${basePath}/${id}/link`, personalCaregiver);
};

export const getPersonalCaregiverNotes = async (id, params) => {
    return getRequest(`${basePath}/${id}/notes`, params);
};

export const postPersonalCaregiverNote = async (id, note) => {
    return postRequest(`${basePath}/${id}/notes`, note);
};

export const getPersonalCaregiverTags = async (id, params) => {
    return getRequest(`${basePath}/${id}/tags`, params);
};

export const postPersonalCaregiverTag = async (id, tag) => {
    return postRequest(`${basePath}/${id}/tags`, tag);
};
export const getPersonalCaregiverPhoneNumbers = async (id, onlyE164Format) => {
    return getRequest(`${basePath}/${id}/phone-numbers`, {
        onlyE164Format: onlyE164Format,
    });
};

export const postPersonalCaregiverPhoneNumber = async (id, number) => {
    return postRequest(`${basePath}/${id}/phone-numbers`, number);
};

export const getPersonalCaregiverHolidayLinks = async (id, params) => {
    return getRequest(`${basePath}/${id}/holiday-links`, params);
};

export const postPersonalCaregiverHolidayLink = async (id, holidayLink) => {
    return postRequest(`${basePath}/${id}/holiday-links`, holidayLink);
};

export const stateManagement = {
    ...createStateManagementFunctions(basePath, 'personal-caregivers'),
};
