import {
    EdsDataGridHeader,
    EdsLink,
    EdsPage,
    EdsTabs,
} from '../../../../../components';
import { useLoaderData, useRouteLoaderData } from 'react-router-dom';
import {
    EventSchedule,
    FlowLogsVpc,
    Information,
    Notebook,
    UserData,
} from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';

export default function PersonalCaregiverPageDetails() {
    const loaderData = useLoaderData();
    const personalCaregiver = loaderData?.personalCaregiverResponse ?? {};
    const client = useRouteLoaderData('client/id');
    const { t } = useTranslation();

    const tabs = [
        {
            label: t('27792947ed5d5da7c0d1f43327ed9dab', 'Details'),
            icon: Information,
            transparent: true,
            index: true,
        },
        {
            label: t('4358b5009c67d0e31d7fbf1663fcd3bf', 'Notes'),
            icon: Notebook,
            transparent: true,
            slug: 'notes',
        },
        {
            label: t('f24431ce9f1b8885678b1ed611c4c214', 'Availability'),
            icon: EventSchedule,
            transparent: true,
            slug: 'availability',
        },
        {
            label: t('2165e4fa5bddb65a31f6a0c495c2fa37', 'Logs'),
            icon: FlowLogsVpc,
            transparent: true,
            slug: 'logs',
        },
    ];

    return (
        <EdsPage
            title={personalCaregiver?.person?.combinedName}
            breadcrumb={true}
        >
            <EdsDataGridHeader
                gridData={{
                    styles: {
                        width: '1000px',
                        left: {
                            key: {
                                width: '150px',
                            },
                        },
                        right: {
                            key: {
                                width: '150px',
                            },
                        },
                    },
                    rows: {
                        left: [
                            {
                                key: t(
                                    'f610124a07c580056177271d6664e137',
                                    'Caregiver type'
                                ),
                                value: personalCaregiver.caregiverType?.name,
                            },
                            {
                                key: t(
                                    '884d9804999fc47a3c2694e49ad2536a',
                                    'Address'
                                ),
                                value: personalCaregiver.combinedAddressField,
                            },
                        ],
                        right: [
                            {
                                key: t(
                                    '807765384d9d5527da8848df14a4f02f',
                                    'Links'
                                ),
                                value: (
                                    <EdsLink
                                        to={`/crm/client/${client.id}`}
                                        icon={UserData}
                                        label={client.person?.combinedName}
                                    />
                                ),
                            },
                        ],
                    },
                }}
            />

            <EdsTabs tabs={tabs} router={true}></EdsTabs>
        </EdsPage>
    );
}
